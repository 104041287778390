<template>
  <v-container pa-0>
    <v-row dense class="car-list scrollable">
      <v-col cols="11" sm="6" md="4" lg="3" xl="2" v-for="car in cars" :key="car.id">
        <v-skeleton-loader v-if="car.id === '-1'" type="card" height="114"></v-skeleton-loader>

        <v-card
          v-else
          :color="selected && selected.id === car.id ? 'red darken-4' : ''"
          :dark="selected && selected.id === car.id"
          @click="$emit('select', car)"
        >
          <v-img
            height="70"
            src="@assets/dallara-gp208.png"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title class="pa-1">{{ car.name }}</v-card-title>
          </v-img>
          <v-card-text>
            <v-row>
              <div class="text-subtitle-1 pa-1">{{ car.season }}</div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CarSelection',
  model: {
    prop: 'selected',
    event: 'select'
  },
  props: {
    selected: {
      type: Object
    },
    cars: {
      type: Array,
      required: true
    }
  },
  watch: {
    cars(newCars) {
      if (!newCars) return
      this.$emit('select', this.selected || newCars[0])
    }
  }
}
</script>

<style scoped>
.car-list {
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
