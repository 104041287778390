<template>
  <v-card elevation="6" outlined :to="`/setup/${setup.id}`" height="100%">
    <v-img src="@assets/dallara-gp208.png" height="125"></v-img>
    <v-card-title class="text-h5" v-text="setup.title"></v-card-title>
    <v-card-subtitle>By: {{ editors }}</v-card-subtitle>

    <v-card-text>
      <v-chip-group column>
        <v-chip color="info" outlined>Created {{ $luxon(setup.createdAt) }}</v-chip>
        <v-chip color="primary" outlined>Edited {{ $luxon(lastTimeEdited) }}</v-chip>
      </v-chip-group>
    </v-card-text>
    <v-card-text v-text="setup.notes"></v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SetupMenuCard',
  props: { setup: Object },
  computed: {
    editors() {
      if (!this.setup.responsibles) return []
      const editors = this.setup.responsibles
        .map((res) => {
          return res.responsible.fullname
        })
        .join(', ')
      return editors
    },
    lastTimeEdited() {
      if (!this.setup.responsibles) return new Date().toISOString()
      const editedAt = this.setup.responsibles
        .map((res) => {
          return new Date(res.editedAt)
        })
        .sort()
      if (!editedAt.length) return new Date().toISOString()
      return editedAt[0].toISOString()
    }
  }
}
</script>
